@import '../../index.scss';

.languages-selection {
  border: 2px solid $secondaryColor !important;
  margin-right: 12px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 75px;
  img {
    margin-right: 8px;
  }
  .p-dropdown-trigger {
    display: none;
  }
}
.languages-selection:hover {
  background-color: $primaryHoverColor;
  border: 2px solid $secondaryColor;
  color: $secondaryColor;
}
.languages-selection:focus {
  background-color: $primaryColor;
  border: 2px solid $secondaryColor;
  color: $secondaryColor;
  box-shadow: none !important;
}

.country-item {
  display: flex !important;
}

.country-item-value {
  display: flex !important;
}
.lang-span {
  color: $secondaryColor;
  font-weight: 700;
}
.mobile-language {
  display: none;
}
.MobileLanguage {
  display: none;
}

@media screen and (max-width: 767px) {
  .languages-selection {
    display: none;
  }
  .MobileLanguage {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    .mobile-language {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $primaryColor;
      .mobile-lang-button {
        width: 100%;
        display: flex;
        align-items: center;
        height: 17vh;
        justify-content: flex-start;
        background-color: #fff;
        box-shadow: 0px 3px 5px #00000040;
        padding-left: 20px;
        button {
          margin-right: 15px;
          background: white;
          border: 2px solid $secondaryColor;
          border-radius: 6px;
          padding: 10px;
          color: $secondaryColor;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}

.selected-button{
  background-color: #a52125 !important;
  color: white !important;
}
