.ModelButtons {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 67px;
    .degrees-button {
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        padding: 8px 5px;
        img {
            width: 30px;
            height: 30px;
        }
    }
    .three-d-button {
        display: flex;
        justify-content: center;
        padding: 9.5px 9px;
        img {
            width: 27px;
            height: 27px;
            margin: 0;
        }
    }

}