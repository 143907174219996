@import '../../../../../index.scss';

.add-feature-container {
  .p-dialog-header {
    color: $secondaryColor;
    padding: 6px 6px 0 !important;
  }
}
  .add-digi-header {
    font-size: 16px;
    color: black;
    margin: 0;
    padding-left: 13px;
  }
.add-form-data-container{
  display: flex;
  height: 405px;
  justify-content: space-between;
  margin: 30px 0 30px 15px;
  overflow-y: auto;
  padding-right: 30px;
  width: 350px;
  .form-data {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  .img {
    width: 260px;
    height: 150px;
  }
}

.add-confirm-buttons{
  display: flex;
  justify-content: flex-end;
  
  .p-button {
    margin: 5px;
  }
}

p-dropdown p-dropdown-label{
  display: flex;
  align-items: center;
}
