@import '../../../index.scss';

.info-panel {
  position: absolute;
  transition: 1s;
  background: $primaryColor;
  border: 1px solid $secondaryColor;
  border-radius: 6px;
  width: 340px;
  right: 0;
  z-index: 2;
  padding-right: 3px;
  font-family: 'Inter';
  .pi-times {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 6px;
    right: 6px;
    color: $secondaryColor;
    cursor: pointer;
  }
  .info-pano-button {
    display: flex;
    padding-left: 30px;
  }
  .p-text-left {
    color: $secondaryColor;
    display: flex;
    padding: 10px 10px 10px 30px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
  }

  .feature-information {
    max-height: 445px;
    overflow-y: auto;
    .description {
      padding: 0px 10px 5px 30px;
      font-size: 15px;
      font-weight: 400;
      overflow: hidden;
      text-align: left;
      margin: 0;
      margin-bottom: 10px;
      .show-button {
        color: $secondaryColor;
        border: none;
        background-color: $primaryColor;
        cursor: pointer;
        font-size: 15px;
      }
    }
    .units {
      margin-bottom: 20px;
      li {
        margin-bottom: 5px;
        text-align: left;
        padding-left: 30px;
      }
    }
    .feature-details {
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      padding: 20px 10px 5px 0;
      overflow-y: auto;
      .info-pano-button {
        display: flex;
        margin-bottom: 30px;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      .detail-header {
        font-weight: 700;
        margin-bottom: 5px;
      }
      .adress {
        margin-bottom: 25px;
      }
      .web-site {
        margin-bottom: 25px;
        a {
          color: $secondaryColor;
        }
      }
    }
    .info-headers {
      margin: 20px 0;
      font-weight: 600;
      font-size: 16px;
    }
    .build-information {
      .rows {
        font-size: 14px;
        .info-alias {
          margin: 0 0 3px 0;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
        }
        .info-values {
          margin: 0 0 10px 0;
          padding-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .info-panel {
    left: 0;
    width: 100%;
    bottom: 0;
    border: none;
    position: absolute;
    height: 11vh;
    top: unset;
    border-radius: 0;
    .vr {
      border-top: 6px solid #dee2e6;
      margin: 10px 15px 0 15px;
      width: 51px;
      position: absolute;
      margin-top: 10px;
      display: flex;
      left: 170px;
      border-radius: 4px;
    }
  }
  .info-panel .p-text-left {
    padding: 10px 10px 10px 30px;
    margin-bottom: 10px;
    margin-top: 25px;
  }
}
