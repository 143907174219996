@import '../../../index.scss';

.pano {
    width: 100%;
    display: flex;
    margin: 0;

    canvas {
        max-width: 100%;
    }

    .pano-draw-button {
        position: absolute;
        top: 5px;
        left: 5px;

        .button-primary {
            width: 46px;
        }
    }

    .pano-draw-button-group {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        position: absolute;
        left: 5px;
        top: 50px;

        .button-group {
            margin-bottom: 5px;

            .button-primary {
                width: 46px;
                height: 42px;
            }
        }
    }

    .pano-brightness {
        border: 2px solid;
        border-radius: 6px;
        color: $primaryColor;
        top: 55px;
        bottom: 0;
        position: absolute;
        right: 20px;
        height: 120px;
    }

    input[type=range].pano-slider {
        writing-mode: bt-lr;
        -webkit-appearance: slider-vertical;
        width: 6px;
        height: 120px;
        cursor: grab;
        accent-color: $secondaryColor;
        // height: 6px;
        position: absolute;
        right: 18px;
        top: 50px;
    }
}