@import '../../../index.scss';

.boxes{
  display: flex;
  align-items: center;
  width: 100%;
  margin:6px;
  .image-box {
    border: 1px solid $secondaryColor;
    border-radius: 5px;
    width: 70px;
    height: 70px;
    &:hover {
      cursor: pointer;
    }
   
  }
  .group {
    width: 100%;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-size: 13px;
      margin: 0 0 12px 4px;
      text-align: start;
    }
  }
}

