@import '../../../index.scss';

.menubar {
    border-radius: 0;
    box-shadow: 0px 3px 5px #00000040;
    background-color: $backgroundColor;
    width: 100%;
    display: flex;
    padding: 9px 10px;
    z-index: 3;

    .menubar-items {
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .customer-link {
            display: flex;
        }

        .anadolu-logo {
            width: 35px;
            height: 30px;
        }

        a:-webkit-any-link {
            color: $secondaryColor;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .center-item {
        display: flex;

        .categorical-search {
            margin-left: 20px;
            width: 346px;
        }
    }

    .right-item {
        display: flex;

        .language-selection {
            border: 2px solid $secondaryColor;
        }

        &:enabled:hover {
            background-color: $primaryHoverColor;
            border: 2px solid $secondaryColor;
            color: $secondaryColor;
            opacity: 1 !important;
        }

        &:focus {
            background-color: $secondaryColor;
            color: $primaryColor;
            box-shadow: none;
        }
    }
}

@media screen and (max-width: 960px) {

    //prime reactta ekran bu boyuttayken mobile menu cikiyor. bu sebepten yazildi.
    .p-menubar .p-menubar-button {
        display: none;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: none;
    }
}

@media print {
    .categorical-search {
        width: 225px !important;
    }
}