.QuickSearch {
    .pi-search {
      position: fixed;
      top: 24px; 
      z-index: 1;
      margin-left: 15px;
      cursor: pointer;
    }
    .pi-times {
      position: fixed;
      top: 24px; 
      margin-left: -22px;
      cursor: pointer;
    }
    .p-inputtext {
      padding: 0.75rem 2.5rem 0.75rem 3rem;
      width: 330px;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
}

@media print { 
  .p-inputtext {
    width: 225px !important;  
  }
}

@media screen and (max-width: 700px) {
  .QuickSearch {
    .p-inputtext {
      width: 84vw;
    }
 }
}