.delete-object-container {
    font-size: 14px;
    width: 400px;
    height: 100px;
    .delete-confirm-text {
      padding: 3px 0 15px 3px;
      span {
        padding: 5px;
      }
    }
    .delete-confirm-buttons {
      display: flex !important;
      justify-content: flex-end;
  
      .p-button {
        margin: 3px;
      }
    }
  }