.Toolbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    .toolbar-left-content {
        margin-left: 10px;
        transition: .3s;
        display: flex;
        justify-content: flex-start;
        flex:1;
        .basemap-content{
                margin-right: 5px;
                pointer-events: all;
        }
        .p-button {
            margin-right: 12px;
            pointer-events: all;
            padding: 8px;
            // opacity: 0.95;
        }
    }

    .opened-sidebar {
        margin-left: 305px;
    }
   
    .toolbar-right-content {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        
        .p-button {
            margin-bottom: 7%;
            pointer-events: all;
            padding: 8px;
            width: 130px;
        }
    }

    a {
        all: unset;
    }

}