@import '../../index.scss';

.sharebox-header {
  color: $secondaryColor;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}
.sharebox-container {
  display: flex;
  justify-content: space-between;
  width: 390px;
  position: static;
  margin: 30px 0 15px 0;
  // .p-dialog-draggable .p-dialog-header {
  //   // padding: 6px 6px 20px 23px;
  // }
  .p-dialog .p-dialog-header {
    padding: 0.5rem 0 0.5rem 1.5rem !important;
    color: $secondaryColor;
  }
  .sharebox-input {
    .copy-link-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $primaryColor;
      background: $secondaryColor;
      opacity: 1;
      width: 200px;
      border-radius: 6px;
      height: 45px;
      cursor: pointer;
      padding: 0 12px;
    }
    .p-inputtext {
      color: $primaryColor !important;
    }
    i {
      color: $primaryColor;
      font-size: x-large;
      font-weight: 500;
    }
  }
  .social-media-icons {
    display: flex;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $secondaryColor;
      border-radius: 6px;
      width: 50px;
      height: 45px;
      background: $secondaryColor;
      color: $primaryColor;
      margin-left: 12px;
      .pi-whatsapp,
      .pi-twitter,
      .pi-facebook {
        font-size: 25px;
        font-weight: 500;
        color: $primaryColor;
      }
    }
  }
}
.mobile-sharebox-container {
  display: none;
}

@media screen and (max-width: 767px) {
  .sharebox-container {
    display: none;
  }
  .mobile-sharebox-container {
    display: flex;
    position: absolute;
    background-color: $primaryColor;
    top: 0;
    width: 100%;
    flex-direction: column;
    .mobile-container {
      display: flex;
      align-items: center;
      padding-left: 20px;
      height: 17vh;
      box-shadow: 0px 3px 5px #00000040;
      .sharebox-input {
        .copy-link-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: 'Inter';
          color: $primaryColor;
          background: $secondaryColor;
          opacity: 1;
          width: 180px;
          border-radius: 6px;
          height: 45px;
          cursor: pointer;
          padding: 0 12px;
        }
        .p-inputtext {
          color: $primaryColor !important;
        }
        i {
          color: $primaryColor;
          font-size: x-large;
          font-weight: 500;
        }
      }
      .social-media-icons {
        display: flex;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid $secondaryColor;
          border-radius: 6px;
          width: 50px;
          height: 45px;
          background: $secondaryColor;
          color: $primaryColor;
          margin-left: 12px;
          .pi-whatsapp,
          .pi-twitter,
          .pi-facebook {
            font-size: 25px;
            font-weight: 500;
            color: $primaryColor;
          }
        }
      }
    }
  }
}
