@import '../../../index.scss';

.MenuContainer {
  background-color: white;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  .pi-times {
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px 20px;
    font-size: large;
  }
  .menu-tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    box-shadow: 0px -1px 0px 0px $primaryHoverColor;
    .menu {
      width: 100%;
      height: 70px;
      box-shadow: 0px 1px 0px 0px $primaryHoverColor;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 24px;
      span {
        font-size: 18px;
        color: $secondaryColor;
        font-weight: 600;
      }
      .pi-chevron-right {
        color: $secondaryColor;
        opacity: 60%;
      }
    }
  }
}
