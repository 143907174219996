@import '../../../index.scss';

  .sidebar-container{
    background: $primaryColor;
    border: 1px solid $secondaryColor;
    border-radius: 6px;
    position: absolute;
    top: 47px;
    left: 10px;
    padding:5px 0 5px 5px;
    max-width: 306px;
    pointer-events: all;
    max-height: 445px;
    width: 250px;
    overflow: auto;
    color: #000000;

    // .sidebar {
    //   overflow: auto;
    //   height: 485px;
    // }

}

.p-treenode-leaf{
  .p-tree-toggler{
    width: auto !important;
    display: none;
  }
}

.p-treenode-content{
  .p-checkbox{
    order: 3;
    margin-left: auto;
  }
}

.p-treenode{
  padding: 0 0 3px 0 !important;
  // padding-bottom: 2px !important;
}

.p-treenode-icon{
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}