.reports-container {
  width: 400px;
  height: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .layer-dropdown {
    width: 350px;
    margin: 10px 0 10px 0;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .report-type-dropdown {
    width: 350px;
    margin: 10px 0 30px 0;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .report-buttons{
    display: flex;
    justify-content: flex-end;
    width: 350px;
  }
  .cancel-report-button {
    margin-right: 10px;
  }

  .p-button p-component button-primary p-button-sm {
    margin-top: 35px;
    .get-report-button {
      width: 100px;
      margin: 10px 0 10px 0;
    }
  }
}