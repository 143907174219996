.Map {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
}

.coord-toast{
    width: 450px !important;
}