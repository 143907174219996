@import '../../index.scss';

.basemap-container {
  background: $backgroundColor;
  border: 1px solid $secondaryColor;
  border-radius: 6px;
  position: absolute;
  top: 47px;
  left: 112px;
  padding: 0px 10px 8px 12px;
  width: 288px;
  pointer-events: all;
}

.basemap-container2 {
  background: $primaryColor;
  border: 1px solid $secondaryColor;
  border-radius: 6px;
  position: absolute;
  top: 50px;
  left: 10px;
  padding: 0px 10px 8px 12px; 
  width: 288px;
  pointer-events: all;
}

.header {
  text-align: start;
  color: $secondaryColor;
  margin: 18px 5px;
}
.basemap-group {
  display: flex;
  justify-content: space-between;
  padding: 0;
  .basemap {
    margin: 0px 10px 0px 5px;
    max-width: 70px;
    .image-box {
      border: 1px solid $secondaryColor;
      border-radius: 5px;
      width: 70px;
      height: 70px;
      &:hover {
        cursor: pointer;
      }
    }
    .title {
      font-size: 12px;
      padding: 0px 3px;
      margin: 2px;
      line-height: 15px;
    }
  }
}

.layer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 6px;
}

.mobile-header {
  display: none;
}

@media screen and (max-width: 767px) {
  .basemap-container {
    background: #fffdfd;
    position: absolute;
    top: 0px;
    left: 0;
    padding: 0;
    pointer-events: all;
    min-width: 100%;
    max-width: 100%;
    height: 100vh;
    border: none;
  }
  .mobile-header {
    color: $secondaryColor;
    height: 60px;
    display: flex;
    align-items: center;
    font-family: 'Inter';
    box-shadow: 0px 1px 0px 0px $primaryHoverColor;
    padding-left: 20px;
    background-color: $primaryColor;
    .pi-arrow-left {
      font-size: large;
      margin-right: 11px;
    }
    span {  
      font-size: 18px;
      color: #a52125;
      font-weight: 600;
    }
  }
  .map-group {
    padding: 20px 0;
    .header {
      margin: 0 0 20px 20px;
    }
    .basemap-group {
      display: flex;
      justify-content: flex-start;
      padding-left: 4px;
      .basemap {
        margin: 0;
      }
    }
    .layer {
      padding-left: 20px;
      margin: 0;
    }
  }
}
