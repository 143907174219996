@import 'index.scss';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: sans-serif;

  .body {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;

    .panorama-opened {
      transition: 0.5s;
      visibility: visible;
      width: 100%;
    }

    .main-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: 0.5s;

      .toolbar {
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 10px;
        pointer-events: none;
      }

      .container {
        display: flex;
        width: 100%;
        height: 100%;

        .map-container {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
        }

        .panorama-closed {
          display: none;
        }

        .p-splitter-gutter {
          z-index: 2;
          background-color: $secondaryColor;

          .p-splitter-gutter-handle {
            background-color: $secondaryColor;
          }
        }

        .panorama-container {
          display: flex;
          width: 100%;
          height: 100%;
          z-index: 2;
          position: relative;

          .pi-times {
            position: absolute;
            color: $secondaryColor;
            right: 5px;
            top: 5px;
            cursor: pointer;
            z-index: 1;
            border: 2px solid;
            border-radius: 50%;
            padding: 10px;
            background: $primaryColor  !important;

            // opacity: 0.8 !important;
            &:hover {
              background-color: $secondaryHoverColor;
            }
          }
        }
      }

      .container-table-opened {
        height: 55vh !important;
      }

      .ankageo-logo {
        position: absolute;
        bottom: 0;

        img {
          height: 22px;
          pointer-events: all;
        }
      }

      .table {
        height: 0;
        visibility: hidden;
        transition: 0.2s;
      }

      .table-opened {
        visibility: visible;
        height: 425px;
        width: auto;

        tbody {
          overflow-y: auto !important;
        }
      }
    }
  }

  .anadolu-gif {
    display: block;
    position: absolute;
    z-index: 10;

    img {
      width: 100vw;
      height: 100vh;
    }
  }

  .anadolu-worker-gif {
    display: none;
    position: absolute;
    z-index: 10;

    img {
      width: 100vw;
      height: 100vh;
    }
  }
}

.Language {
  .pi-chevron-down {
    display: none;
  }
}

.p-paginator {
  padding: 0;
  border: 0;
  font-size: small;
}

.p-paginator .p-dropdown {
  height: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .mobile-logo {
    position: absolute;
    bottom: 0;
  }

  .panorama-container {
    visibility: visible;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
  }

  .panorama-container2 {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
    visibility: hidden;
    display: none;

    .pi-times {
      position: absolute;
      color: $secondaryColor;
      right: 5px;
      top: 5px;
      cursor: pointer;
      z-index: 1;
      border: 2px solid;
      border-radius: 50%;
      padding: 10px;
      background: $primaryColor  !important;

      // opacity: 0.8 !important;
      &:hover {
        background-color: $secondaryHoverColor;
      }
    }
  }

  .select-panogps {
    position: absolute;
    z-index: 10;
    background-color: white;
    padding: 10px 10px 10px 20px;
    border-radius: 10px 10px 0px 0px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      outline: none;
      border: none;
      background-color: white;

    }
  }

  .container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .map-container {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }


}