@import 'primereact/resources/themes/lara-light-blue/theme.css'; //theme
@import 'primereact/resources/primereact.min.css'; //core css
@import 'primeicons/primeicons.css';

$primaryColor: #fff;
$secondaryColor: #a52125;

$primaryHoverColor: #fec3c4;
$secondaryHoverColor: #651114;

$secondaryFocusColor: #f6b8ba;
$backgroundColor: #fffdfd;

body,
html {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 14px;
  user-select: none;
}

.button-primary {
  background-color: $primaryColor;
  color: $secondaryColor;
  border: 2px solid $secondaryColor;
  opacity: 1;
  transition: none;
  display: flex;
  justify-content: center;
  &:enabled:hover {
    background-color: $primaryHoverColor;
    border: 2px solid $secondaryColor;
    color: $secondaryColor;
    opacity: 0.8 !important;
  }
  &:focus {
    background-color: none;
    color: none;
    box-shadow: none;
  }
  &:active{
    background-color: none !important;
    color: none;
    box-shadow: none;
  }
}

.button-primary-active {
  background-color: $secondaryColor;
  color: $primaryColor;
  box-shadow: none;
}

.p-button:enabled:active {
  background-color: $secondaryColor !important;
  color: $primaryColor;
  border: 2px solid $secondaryColor !important;
}

.button-secondary {
  background-color: $secondaryColor;
  border: 2px solid $secondaryColor;
  opacity: 0.9;
  color: $primaryColor;
  transition: none;
  &:enabled:hover {
    background-color: $secondaryHoverColor;
    border: 2px solid $secondaryHoverColor;
  }
  &:focus {
    box-shadow: none;
  }
}
.pi {
  font-weight: bold;
}

.p-button-label {
  font-size: 14px;
  font-weight: bold;
}

.p-menubar-start {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.p-slider-handle {
  border: 2px solid $secondaryColor !important;
  &:hover {
    background: $secondaryColor !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $primaryHoverColor !important;
  }
}

.p-slider .p-slider-range {
  background: $secondaryColor;
}

// sidebar icin
.p-tree {
  border: none !important;
  padding: 5px !important;
  overflow-y: auto;
}
.p-tree .p-tree-container .p-treenode {
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  &:focus {
    box-shadow: 0 0 0 0.2rem #dee2e680;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: $primaryColor;
  color: #495057;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  color: $secondaryColor;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: $secondaryColor;
  background-color: $primaryHoverColor;
}

.p-sidebar .p-sidebar-header {
  display: flex;
  padding: 1.25rem 0.75rem 1.5rem 1.8rem;
  color: $secondaryColor;
  font-weight: 600;
  font-size: larger;
}

.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler {
  color: $secondaryColor;
  &:hover {
    color: $secondaryColor;
  }
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon {
  color: $secondaryColor;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  &:focus {
    box-shadow: none;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
  border: 2px solid #ced4da;
  box-shadow: none !important;
  border-color: none !important;

  &:hover {
    border: 2px solid $secondaryColor;
    box-shadow: none;
  }
  &:focus {
    border: 2px solid $secondaryColor;
    box-shadow: none;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: $secondaryColor !important;
  border: 2px solid $secondaryColor !important;
  border-color: #ced4da !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
  border: 2px solid #ced4da;
  box-shadow: none;
  border-color: $secondaryColor !important;
  color: #ffffff;
  &:hover {
    border-color: $secondaryColor;
    background: $secondaryColor;
    color: #ffffff;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border: 2px solid #ced4da !important;
  border-color: $secondaryColor !important;
}

.p-tree-container {
  overflow: hidden;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 5px;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  // box-shadow: 0 0 0 0.1rem #6c757d;
  box-shadow: none;
}
.p-sidebar .p-sidebar-content {
  padding: 1.25rem 0 1.25rem 1.25rem;
  overflow: hidden;
}
//sidebar bitti

//popup icin
.p-dialog-header-close-icon {
  color: #a52125;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: $primaryHoverColor;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}
.p-dialog {
  .p-dialog-content {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    padding: 0 1.5rem 1rem 1.5rem;
  }
}
.p-dialog .p-dialog-header .p-dialog-title {
  color: $secondaryColor;
}
.p-dialog .p-dialog-header {
  padding: 0.5rem 0.5rem 0 1.5rem !important;
  color: $secondaryColor;
}

.p-dialog-visible {
  z-index: 3 !important;
}

//popup bitti

//kategorik arama icin
.p-treeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}
.p-treeselect:not(.p-disabled).p-focus {
  box-shadow: 0px 0px 5px 1.5px #ced4da;
  border-color: #ced4da;
}
.p-treeselect-header {
  display: none;
}
//kategorik arama bitti

//hizli arama icin
.p-inputtext:enabled:hover {
  border-color: #ced4da;
}
.p-inputtext:enabled:focus {
  box-shadow: 0px 0px 5px 1.5px #ced4da;
  border-color: #ced4da;
}
.p-autocomplete-panel {
  max-width: 310px;
}
.p-autocomplete-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-autocomplete .p-autocomplete-loader {
  display: none;
}
//hizli arama bitti

// sidebar yazılar sola yaslandı
.p-treenode-label {
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-align: left;
}
// sidebar yazılar sola yaslandı bitti

// //splitter icin
.p-splitter {
  width: 100%;
  border-radius: 0;
}
// .p-splitter-panel {
//   flex-basis: calc(23% - 4px) !important;
// }

//splitter bitti

//dil secenegi icin
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  // color: $secondaryColor;
  color: #495057;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 5px 10px 8px;
  margin: 3px 0;
  img {
    margin-right: 5px;
  }
}
.p-dropdown-panel {
  left: 1077px;
  width: 72px;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $primaryColor;
  color: #495057;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:hover {
  background: #e9ecef;
  color: #495057;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
  color: #495057;
}

// .p-dropdown .p-dropdown-trigger {
//   color: $secondaryColor !important;
//   padding: 0 10px 0 0 !important;
//   width: 0 !important;
// }

// .p-dropdown {
// border: 2px solid $secondaryColor;
// margin-right: 12px !important;
// height: 39px;
// display: flex;
// justify-content: center;
// align-items: center;
// font-weight: 600;
// width: 70px;
// img {
//   margin-right: 8px;
// }
// }

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid #ced4da;
}
.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid #ced4da;
  box-shadow: none !important;
}

.p-dropdown .p-dropdown-label {
  // color: $secondaryColor;
  display: flex;
  padding: 0 5px 0 6px;
  font-weight: 500;
  color: #6c757d;
}

.p-treeselect .p-treeselect-label {
  text-align: left;
}
//dil secenegi bitti

//draw button clicked
.active-draw-button {
  background-color: $primaryHoverColor;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  display: flex;
  align-items: center;
}

.p-checkbox .p-checkbox-box.p-highlight {
  width: 20px !important;
  height: 20px !important;
}

.long-button {
  width: 100%;
}

//scroll
/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);
  border-radius: 6px;
}

//table
.p-datatable-wrapper {
  font-size: 12px;
  overflow: auto;
  width: auto;
  height: 300px;
  .p-datatable-thead {
    tr {
      position: sticky !important;
      white-space: nowrap;
      height: 10px !important;
      th {
        width: 10px !important;
        background-color: #eeee !important;
        position: sticky;
      }
    }
  }
}

// .p-datatable table {
// border-collapse: unset;
// }
.p-datatable .p-datatable-tbody > tr {
  font-weight: 500;
  overflow: hidden;
  text-align: left;
}

.p-datatable .p-datatable-tbody > tr > td {
  min-width: 150px !important;
  max-width: 150px !important;
  min-height: 25px !important;
  max-height: 25px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding: 7px 11px 7px 10px;
  overflow: hidden;
}
.p-datatable .p-datatable-tbody > tr > td:hover {
  cursor: pointer;
}
.p-datatable .p-datatable-thead > tr > th {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0px;
  z-index: 1;
  padding: 5px 5px 5px 10px;
}
.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: initial !important;
  max-height: 275px;
}

.p-datatable-resizable .p-datatable-resizable-column {
  position: sticky !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: #ffecec;
  color: #6b6b6b;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-highlight {
  background: #ffecec;
  color: #6b6b6b;
}
//table bitti

//context menu
.p-contextmenu {
  width: 150px;
  box-shadow: black 0px 0px 5px;
}

.p-contextmenu .p-menuitem-link {
  padding: 10px 0px 10px 10px;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: $primaryHoverColor;
}

.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text {
  color: $secondaryHoverColor;
  // font-weight: 500;
}

//paginator
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e9ecef;
  border-color: #e9ecef;
  color: #343a40;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
}

.pi-sign-out {
  font-size: 17px !important;
}

// .p-toast {
//   width: auto;
// }

//print css
@media print {
  //hizli arama listesi icin
  .p-autocomplete-panel {
    left: 300px !important;
    max-width: 225px !important;
    min-width: 225px !important;
  }
}

input[type=range].styled-slider{
  cursor: grab;
  accent-color: $secondaryColor;
  height: 6px;
}
