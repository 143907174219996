@import '../../index.scss';

.content{
    display: flex;
    flex-direction: column;
    width: 450px;
    user-select: none;
    .header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .login-container{
        .input-group{
            .p-field{
                display: flex;
                flex-direction: column;
                margin: 2% 0;
                .p-d-block{
                    margin: 2% 0;
                }
            }
        }
        .group{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8% 0 6% 0;
            .p-col-12{
                display: flex;
                align-items: center;
                #remember-me{
                    display: flex;
                    align-items: center;
                    margin-right: 6px;
                }
                label{
                    white-space: nowrap;
                }
            }
            .forgot-pass{
                color: $secondaryColor;
                font-weight: bold;
            }
        }
    }
   
}
.Login {
    .entry-exit {
        padding: 9px 0px 9px 0px !important;
        width: 38px !important;
    }
    .pi-sign-in {
        font-size: 17px !important;
    }
}
.p-password input {
    width: 32rem;
}
.pi-eye:hover {
    cursor: pointer !important;
}
.pi-eye-slash:hover {
    cursor: pointer !important;
}
 
.login-toast{
    // z-index: 8 !important;
}

.popup{
    // z-index: 5 !important;
}