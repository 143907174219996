@import '../../../index.scss';

.card{
    pointer-events: all;
    // .table-style {
        //background-color: lightgray;
    // }
    .p-datatable-header{
        padding: 8px 0 5px 0;
        height: 53px;
        position: sticky;
        .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0px 0 15px;
            .header-right{
                display: flex;
                align-items: flex-start;
                .global-search{
                    padding-right: 20px;
                }
                .pi-times{
                    cursor: pointer;
                    padding: 10px 12px 0 0px;
                }
            }
            .header-span {
                text-align: left;
                font: normal normal bold 14px Inter;
                letter-spacing: 0px;
                color: #343a40;
                padding-top: 6px;
            }
            .vr{
                border-left: 1px solid #868686;
                height: 30px;
                margin: 0 15px 0 15px;
            }
            .table-tools {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                .table-tools-part {
                    display: flex;
                }
            }
            .table-button {
                border: 1px solid $secondaryColor;
                color: $secondaryColor;
                font-size: 12px;
                border-radius: 4px;
                padding: 6px 8px;
                white-space: nowrap;
                margin-right: 7px;
                height: 30px;
                display: flex;
                align-items: center;
                cursor: pointer;
                background: $primaryColor;
                font-weight: 600;
                font-family: 'Inter';
            }
            .table-button-disabled {
                border: 1px solid $secondaryColor;
                color: $secondaryColor;
                font-size: 11px;
                border-radius: 6px;
                padding: 6px 8px;
                white-space: nowrap;
                margin-right: 7px;
                height: 30px;
                display: flex;
                align-items: center;
                cursor: not-allowed;
                opacity: 0.5;
            }
            .geometry-edit-button-active {
                background-color: $secondaryColor;
                color: $primaryColor;
                box-shadow: none;
            }
        }
    }  
    
}
.p-column-filter-operator {
    .p-dropdown{
        display: flex;
        align-items: center;
        height: 36px !important;
        width: 180px;
    }
}
.p-column-filter-constraint {
    .p-dropdown{
        display: flex;
        align-items: center;
        height: 36px !important;
        width: 180px;
    }
    .p-inputtext {
        width: 180px;
    }
}

.p-column-filter-add-rule{
    display: none;
}

.p-column-filter-operator{
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar{
    padding: 0 1.25rem 1.25rem 1.25rem;
    justify-content: flex-end !important;
     button{
        display: flex;
        justify-content: center;
        width: 65px !important;
        height: 28px !important;
        margin-left: 6px;
        background-color: $secondaryColor;
        color: white;
        border: 1px solid $secondaryColor;
        &:hover{
            background-color: $secondaryColor;
            color: white;
            border: 1px solid $secondaryColor;
        }
        span{
            text-align: center !important;
            font-size: 13px;
        }
     }
     .p-button.p-component.p-button-outlined{
        background-color: white ;
        color: $secondaryColor !important;
        border: 1px solid $secondaryColor;         
           &:hover{
            background-color: white ;
            color: $secondaryColor !important;
            border: 1px solid $secondaryColor;         
        }
     }
}

.p-datatable .p-sortable-column.p-highlight{
    color: $secondaryColor;
    &:hover{
        color: $secondaryColor !important;
    }
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{
    color: $secondaryColor !important;
}

.p-datatable .p-sortable-column:focus{
    box-shadow: inset 0 0 0 1px $secondaryFocusColor;
}
.p-column-filter-menu-button:focus{
    box-shadow: 0 0 0 0.2rem $secondaryFocusColor;
}

.p-link:focus{
    box-shadow: 0 0 0 0.2rem $secondaryFocusColor;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active{
    color:  $secondaryColor !important;
}
// .edit-delete{
//     // cursor: pointer;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     // width: 100%;
//     // height: 50px;
//     padding: 0px 11px;
//     width: 100px !important;
//     min-width: 100px !important;
//     max-width: 100px !important;
//     height: 25px !important;
//     min-height: 25px !important;
//     max-height: 25px !important;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     margin: 0;
// }

