@import '../../../index.scss';

.MenuDetails {
  background-color: white;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .menu-header {
    display: flex;
    align-items: center;
    color: $secondaryColor;
    box-shadow: 0px 1px 0px 0px $primaryHoverColor;
    height: 68px;
    .pi-arrow-left {
      margin: 20px 20px 20px;
      font-size: large;
    }
    span {
      font-size: 18px;
      color: $secondaryColor;
      font-weight: 600;
    }
  }
  .menu-container-box {
    background-color: rgb(163, 162, 162);
    height: 100px;
  }
}
