.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 17px;
  box-shadow: 0px 3px 5px #00000040;
  .pi-search {
      display: none;
  }
  .p-inputtext {
    padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  }
}
