@import '../../index.scss';

.edit-object-container {
  .p-dialog-header {
    color: $secondaryColor;
    padding: 6px 6px 0 !important;
  }
}
//prime react fileupload
.p-fileupload .p-fileupload-row > div {
  padding: 0 !important;
}
.p-fileupload .p-fileupload-content {
  padding: 0;
}
.p-fileupload-row > div:last-child {
  text-align: left;
}
.file-upload-container {
  width: 280px !important;
}
.p-fileupload .p-fileupload-content {
  padding: 0 !important;
}
.file-footer {
  display: flex;
  justify-content: space-between;
  &:hover {
    color: #495057;
  }
}
.format-size {
  background-color: $primaryColor;
  padding: 0;
}
.delete-file {
  border: none;
  background-color: $primaryColor;
&:hover {
  background-color: $primaryColor !important;
}
}
.pi-times {
  color: $secondaryColor !important;
  font-weight: 600 !important;
}
.file-upload-icon {
  color: red;
  background-color: $primaryColor;
  border: none;
  &:focus {
    box-shadow: none;
  }
&:hover {
  background-color: $primaryColor !important;
}
}
.pi-cloud-upload {
  font-size: 25px !important;
  &:hover {
    color: $secondaryColor !important;
  }
}
//fileupload bitti


.edit-object-header {
  font-size: 16px;
  color: black;
  margin: 0;
  max-width: 550px;
  padding-left: 13px;
}
.form-data-container{
  display: flex;
  width: 350px;
  height: 405px;
  overflow-y: auto;
  margin: 30px 0 30px 15px;
  justify-content: space-between;
  padding-right: 30px;
}
.form-data-properties{
  width: 280px;
}

.attachment-container {
  position: relative;
  .attachment-header {
    color: #495057;
    font-weight: 600;
  }
  .file-container {
    width: 280px;
    border: 1px solid #CED4DA;
    border-radius: 6px;
    margin-top: 5px;
    padding: 0 8px;
    .file-selection {
      display: flex;
      justify-content: space-around;
    }
    span {
      color: #495057;
      font-weight: 500;
      transition: color .3s ease-out;
      font-size: 13px;
      opacity: 1;
      // cursor: pointer;
    }
   .label {
    width: 230px;
    height: 45px;
    border-radius: 4px;
    display: flex;
    color: #495057;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    font-weight: 500;
    font-size: 13px;
    &:hover {
      color: $secondaryColor;
    }
   }
   input {
    display: none;
  }
    .attachment-actions {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
    }
     .file-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      border: 1px solid lightgray;
      border-radius: 4px;
      margin-bottom: 10px;
      width: 260px;
      // height: 150px;
      transition: all .3s ease-out;
      position: relative;
      background-color: lightgray;
      // top: 80px;
      .button-container {
        position: absolute;
        bottom: 10px;
        display: flex;
        left: 90px;
        
      }
      .file-name-container {
        position: absolute;
        top: 5px;
        display: flex;
      }
      .anim {
        display: flex;
      }
      .name {
        display: flex;
        font-size: 17px;
        color: $primaryColor;
      }
      .file-name {
        transition: all .3s ease-out;
        transform: translateY(-30px);
      }
      .anim-from-left {
        transition: all .3s ease-out;
        transform: translateX(-30px);
      }
      .anim-from-right {
        transition: all .3s ease-out;
        transform: translateX(30px);
        margin-left: 10px;
      }
      &:hover {
        .anim  {
          opacity: 1;
        }
        .file-name-container  {
          opacity: 1;
          max-width: 180px;
          max-height: 40px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .anim-from-left, .anim-from-right, .file-name {
          transform: translateX(0);
        }
      }
      img {
        width: 260px;
        height: 150px;
      }
      a {
        opacity: 0;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        background-color: $primaryColor;
        color: $secondaryColor;
        border: 1px solid $secondaryColor;
        transition: none;
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;

      }
      .file-name-container {
        display: flex;
        transition: none;
        opacity: 0;
        max-width: 180px;
        max-height: 40px;
        word-break: break-all;
        left: 10px;
      }
  }
  .p-fileupload .p-fileupload-buttonbar {
    padding: 1.25rem 3px;
  }
  }
}

.edit-confirm-buttons{
    display: flex !important;
    justify-content: flex-end;

    .p-button {
      margin: 5px;
    }
}
.form-data {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  label {
    color: #495057;
    font-weight: 600;
  }
}
.p-image-toolbar {
  z-index: 1;
}
// .file-selection:hover {
//   span {
//     color: $secondaryColor;
//   }
//   i {
//     color: $secondaryColor;
//   }
// }