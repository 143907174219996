@import '../../../index.scss';

.worker-info-panel {
    position: absolute;
    transition: 1s;
    background: $primaryColor;
    border: 1px solid $secondaryColor;
    border-radius: 6px;
    width: 340px;
    right: 0;
    z-index: 2;
    padding-right: 3px;
    font-family: 'Inter';
    max-height: 500px;
    .pi-times {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        top: 6px;
        right: 6px;
        color: $secondaryColor;
        cursor: pointer;
    }
    .p-text-left {
        color: $secondaryColor;
        display: flex;
        padding: 10px 10px 10px 30px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 20px;
        text-align: left;
      }

    .feature-information {
        max-height: 330px;
        overflow-y: auto; 
    .description {
        padding: 0px 10px 5px 30px;
        font-size: 15px;
        font-weight: 400;
        overflow: hidden;
        text-align: left;
        margin: 0;
        margin-bottom: 10px;
        .show-button {
            color: $secondaryColor;
            border: none;
            background-color: $primaryColor;
            cursor: pointer;
            font-size: 15px;
        }
    }
    .units {
        margin-bottom: 20px;
        li {
            margin-bottom: 5px;
            text-align: left;
            padding-left: 30px;
        }
    }
    .feature-details {
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 0px 10px 5px 30px;
        overflow-y: auto;
        .info-pano-button {
            display: flex;
            margin-bottom: 30px;
        }
    }
    .info-headers {
        margin: 20px 0;
        font-weight: 600;
        font-size: 16px;
    }
    .build-information {
        .rows {
            // display: flex;
            font-size: 14px;
        .info-alias {
                margin: 0 0 3px 0;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
        }
        .info-values {
                margin: 0 0 10px 0;
                padding-left: 20px; 
        }
    }
    }
    .file-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 1px solid lightgray;
        border-radius: 4px;
        margin-bottom: 10px;
        width: 215px;
        // height: 150px;
        transition: all .3s ease-out;
        position: relative;
        background-color: lightgray;
        // top: 80px;
        .button-container {
          position: absolute;
          bottom: 10px;
          display: flex;
          left: 85px;
          
        }
        .file-name-container {
          display: flex;
          position: absolute;
          top: 5px;
        }
        .anim {
          display: flex;
        }
        .name {
          display: flex;
          font-size: 17px;
          color: $primaryColor;
        }
        .file-name {
          transition: all .3s ease-out;
          transform: translateY(-30px);
        }
        .anim-from-left {
          transition: all .3s ease-out;
          transform: translateY(30px);
        }
        &:hover {
          .anim  {
            opacity: 1;
          }
          .file-name-container  {
            opacity: 1;
            max-width: 180px;
            max-height: 40px;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .anim-from-left {
              transform: translateY(0px);
          }
           .file-name {
            transform: translateX(0);
          }
        }
        img {
          width: 215px;
          height: 150px;
        }
        a {
          opacity: 0;
          padding: 5px;
          border-radius: 5px;
          cursor: pointer;
          user-select: none;
          background-color: $primaryColor;
          color: $secondaryColor;
          border: 1px solid $secondaryColor;
          transition: none;
          display: flex;
          justify-content: center;
          font-size: 15px;
          font-weight: 500;
  
        }
        .file-name-container {
          display: flex;
          transition: none;
          opacity: 0;
          max-width: 180px;
          max-height: 40px;
          word-break: break-all;
          left: 10px;
        }
    }
}
.info-edit-button {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  }