@import '../../index.scss';

.CategoricalSearch {
  .p-input-icon-right {
    position: relative !important;
    margin-left: 10px;

    .pi-angle-down:before {
      font-size: 18px !important;
      color: #8b8b8b !important;
    }

    .p-inputtext {
      border: 1px solid #acb1b6;
      width: 375px;
      user-select: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .categorical-list {
    position: absolute;
    width: 26.7em !important;
    background: white;
    border-radius: 5px;
    top: 40px;
    user-select: none;
    max-height: 450px;
    overflow: auto;
    box-shadow: 1px 1px 8px #0000002b;

    .group {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .main-header {
        width: 100%;
        padding: 8px 15px;
        display: flex;
        justify-content: flex-start;

        &:hover {
          background: #e9ecef;
        }
      }
    }

    .children {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .child {
        width: 100%;
        padding: 8px 42px;
        display: flex;
        justify-content: flex-start;

        &:hover {
          background: #e9ecef;
        }
      }

      .subchild {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;

        .c {
          width: 100%;
          padding: 8px 5px 8px 48px;
          text-align: start;

          &:hover {
            background: #e9ecef;
          }
        }
      }
    }

    .pi-angle-down:before {
      font-size: 18px !important;
      color: #a52125 !important;
    }
  }

  .focused {
    box-shadow: 0px 0px 5px 1.5px #ced4da;
    border-color: #ced4da;
  }

  .main-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding-left: 20px;
  }

  .p-listbox .p-listbox-list {
    padding: 0 !important;

    >.p-listbox-item {
      padding: 0 !important;
    }
  }

  .pi-angle-right:before {
    font-size: 18px !important;
    color: #8b8b8b !important;
    margin-right: 5px !important;
    display: flex !important;
    align-items: center !important;
  }

  .pi-angle-down:before {
    font-size: 18px !important;
    color: #8b8b8b !important;
    margin-right: 5px !important;
    display: flex !important;
    align-items: center !important;
  }
}

@media screen and (max-width: 767px) {
  .MobileCategoricalSearch {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: $primaryColor;

    .mobile-header {
      color: $secondaryColor;
      height: 60px;
      display: flex;
      align-items: center;
      box-shadow: 0px 1px 0px 0px $primaryHoverColor;
      padding-left: 20px;
      background-color: $primaryColor;
    }

    .mobile-categorical-search {
      background-color: $primaryColor;
      display: flex;
      justify-content: center;
      padding: 20px 0 0;
    }

    .p-input-icon-right {
      top: 30px !important;
    }

    .categorical-list {
      .pi-angle-down:before {
        padding-right: 0px !important;
        padding-top: 0px;
      }
    }

    .pi-angle-down:before {
      padding-right: 58px !important;
    }
  }
}

@media print {
  .p-treeselect-panel {
    left: 542px !important;
    max-width: 225px !important;
    min-width: 225px !important;
  }
}