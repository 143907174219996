@import '../../../../index.scss';

.measurement-container {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  padding: 12px 7px 7px 7px;
  background: #fffdfd;
  border: 1px solid #a52125;
  border-radius: 6px;
  .button-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    button{
      margin-right: 0px !important;
      margin: 0px 6px !important;
    }
    span{
      font-size: 12px !important;
      padding: 3px;
    }
  }
}

.draw-button-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: absolute;
  left: 205px;
  top: 47px;
  .p-button {
    height: 44px;
    width: 44px;
    margin-bottom: 5px;
  }
}
.label {
  display: flex;
  color: $secondaryColor;
}
.draw-button-group2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: absolute;
  left: 100px;
  top: 45px;
  .p-button {
    height: 44px;
    width: 44px;
  }
}

.mobile-draw-header {
  display: none;
}

.finish-button {
  display: none;
}

@media screen and (max-width: 767px) {
  .mobile-draw-header {
    color: $secondaryColor;
    height: 60px;
    display: flex;
    align-items: revert;
    box-shadow: 0px 1px 0px 0px $primaryHoverColor;
    padding: 20px;
    background-color: $primaryColor;
    z-index: 2;
    top: 0;
    position: absolute;
    width: 100%;
    .pi-arrow-left {
      font-size: large;
      margin-right: 11px;
    }
    span {
      font-size: 18px;
      color: $secondaryColor;
      font-weight: 600;
    }
  }
  .measurement-container {
    border: none;
  }
  .draw-button-group {
    top: 60px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    position: absolute;
    align-items: center;
    background: $primaryColor;
    width: 100vw;
    height: 17vh;
    padding: 20px;
    box-shadow: 0px 3px 5px #00000040;

    .button-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .mobile-draw-button {
        margin-right: 20px;
        margin-bottom: 5px;
        width: 40px !important;
        height: 38px !important;
      }
      .active-draw-button {
        margin-right: 20px;
        margin-bottom: 5px;
        width: 40px !important;
        height: 38px !important;
      }
      .passive-draw-button {
        margin-right: 30px;
        width: 40px !important;
        height: 38px !important;
      }
      span {
        display: flex;
        color: $secondaryColor;
      }
      .finish-button {
        position: absolute;
        top: 40px;
        right: 80px;
        font-weight: 600;
      }
    }
  }

  .draw-button-group2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: absolute;
    top: 60px;
    padding: 40px 0;
    margin: 0;
    left: 0;
    width: 100%;
    align-items: center;
    height: 17vh;
    background-color: $primaryColor;
    .button-group {
      margin-left: 20px;
    }
  }
  .finish-button {
    display: flex;
    color: $secondaryColor;
    font-size: 15px;
    font-weight: 600;
  }
}
